// taxonomy: https://docs.google.com/spreadsheets/d/1cuaxHuQRGDNUR5AmPkvwYggLTZJgDYoqiZ9-6gZuCpI
// The COMPONENT_NAME list is currently within this shared scope because it is being used by apps search and search-mystique. After search-mystique removal, specific search components should be moved to /libs/search.
export const COMPONENT_NAME = {
  AUTOCOMPLETE_HISTORY: 'autocomplete_history',
  AUTOCOMPLETE_RESULT: 'autocomplete_result',
  CCP_CAROUSEL_DOCTRINE: 'ccp_carousel_doutrina',
  CCP_CAROUSEL_MODEL: 'ccp_carousel_modelos',
  CCP_CAROUSEL_MOTION: 'ccp_carousel_pecas',
  CCP_CAROUSEL_QUERY_INTENTION: 'ccp_carousel_query_intention',
  CCP_LIST_LAWSUIT_PARTY: 'ccp_list_lawsuit_party',
  CCP_LIST_LAWSUIT: 'ccp_list_lawsuit',
  CCP_MULTIPLE_BOX_DOCTRINE_RECOMMEND: 'ccp_multiple_box_doctrine_recommend',
  CCP_MULTIPLE_BOX_DOCTRINE: 'ccp_multiple_box_doctrine',
  CCP_MULTIPLE_BOX_LEGISLACAO: 'ccp_multiple_box_legislacao',
  CCP_SINGLE_BOX_ARTICLE: 'ccp_single_box_article',
  CCP_SINGLE_BOX_DOCTRINE_TOPIC: 'ccp_single_box_doctrine_topic',
  CCP_SINGLE_BOX_JURISPRUDENTIAL_GUIDANCE: 'ccp_single_box_jurisprudential_guidance',
  CCP_SINGLE_BOX_LAWSUIT: 'ccp_single_box_lawsuit',
  CCP_SINGLE_BOX_LEGISLACAO: 'ccp_single_box_legislacao',
  CCP_SINGLE_BOX_SUMMARY_PRECEDENT: 'ccp_single_box_summary_precedent',
  CCP_SINGLE_BOX_MANDATORY_PRECEDENT: 'ccp_single_box_mandatory_precedent',
  DOCUMENT_FOOTER: 'document_footer',
  DOCUMENT_INDEX: 'document_index',
  JURIS_SHOW_MORE_DETAILS: 'juris_show_more_details',
  LAST_LAWSUIT_VIEWED: 'last_lawsuit_viewed',
  NAME_RELATED_LAWSUIT: 'name_related_lawsuit',
  NAME_RELATED_LAWSUIT_ACCORDION: 'name_related_lawsuit_accordion',
  PERSON_RELATED_LAWSUIT: 'person_related_lawsuit',
  RELATED_DOCTRINE: 'related_doctrine',
  RELATED_DOCUMENTS_ARTICLE: 'related_documents_article',
  RELATED_DOCUMENTS_DOCTRINE: 'related_documents_doctrine',
  RELATED_DOCUMENTS_JOURNAL: 'related_documents_journal',
  RELATED_DOCUMENTS_JURISPRUDENCE: 'related_documents_jurisprudence',
  RELATED_DOCUMENTS_MODEL: 'related_documents_model',
  RELATED_DOCUMENTS_MOTION: 'related_documents_motion',
  RELATED_DOCUMENTS_NEWS: 'related_documents_news',
  RELATED_JURISPRUDENCE: 'related_jurisprudence',
  RELATED_LAW: 'related_law',
  RELATED_MODEL_TOPIC: 'related_model_topic',
  RELATED_NAMES_LIST: 'related_names_list',
  RELATED_SUMMARY: 'related_summary',
  RESULT_SNIPPET_ARTICLE: 'result_snippet_article',
  RESULT_SNIPPET_DOCTRINE: 'result_snippet_doctrine',
  RESULT_SNIPPET_JOURNAL: 'result_snippet_journal',
  RESULT_SNIPPET_JURISPRUDENCE: 'result_snippet_jurisprudence',
  RESULT_SNIPPET_LAW_ARTICLE: 'result_snippet_law_article',
  RESULT_SNIPPET_LAW: 'result_snippet_law',
  RESULT_SNIPPET_LAWSUIT_PARTY: 'result_snippet_lawsuit_party',
  RESULT_SNIPPET_MODEL: 'result_snippet_model',
  RESULT_SNIPPET_MOTION: 'result_snippet_motion',
  RESULT_SNIPPET_NEWS: 'result_snippet_news',
  RELATED_TOPICS_LAW: 'related_topics_law',
  SEARCH_BAR_HOME: 'search_bar_home',
  SEARCH_RESULT_SNIPPET: 'result_snippet',
  SEARCH_OTHER_LAWSUITS: 'search_other_lawsuits',
  SIDE_CONTENT: 'side_content',
  UNRESOLVED_ENTITY_RELATED_LAWSUIT: 'unresolved_entity_related_lawsuit',
}

export const CONTENT_GROUP = {
  SERP: 'serp',
}

export const COMPONENT_CLASS = {
  AUTOCOMPLETE: 'autocomplete',
  CCP_CAROUSEL: 'ccp_carousel',
  CCP_LIST: 'ccp_list',
  CCP_MULTIPLE_BOX: 'ccp_multiple_box',
  CCP_SINGLE_BOX: 'ccp_single_box',
  DOCTRINE_INDEX: 'doctrine_index',
  DOCTRINE_SUMMARY: 'doctrine_summary',
  DOCUMENT_BODY_FULL_CONTENT: 'document_body_full_content',
  DOCUMENT_BODY_SUMMARY: 'document_body_summary',
  DOCUMENT_FOOTER: 'document_footer',
  DOCUMENT_HEADER: 'document_header',
  DOCUMENT_INDEX: 'document_index',
  DOCUMENT_SIDE_CONTENT: 'document_side_content',
  DOCUMENT_TOPBAR: 'document_topbar',
  FINDABILITY_FEEDBACK_MODAL: 'findability_feedback_panel',
  FORMATTED_TEXT_MODAL: 'formatted_text_modal',
  LAWSUIT_ACCORDION: 'lawsuit_accordion',
  LAWSUIT_CARD: 'lawsuit_card',
  LAWSUIT_DETAILS: 'lawsuit_details',
  LAWSUIT_DOCUMENTS: 'lawsuit_documents',
  LAWSUIT_STATUS: 'lawsuit_status',
  NAME_PAGE_HEADER: 'name_page_header',
  NORMATIVE_ACT_BODY: 'normative_act_body',
  PAGE_NAVIGATION: 'page_navigation',
  PERSON_CARD: 'person_card',
  PERSON_SEARCH_PANEL: 'person_search_panel',
  RECOMMENDED: 'recommended',
  RELATED_DOCUMENT_CARROUSSEL: 'related_document_carroussel',
  RELATED_DOCUMENT_POPOVER: 'related_document_popover',
  RELATED_DOCUMENTS: 'related_documents',
  RELATED_NAMES_ACCORDION: 'related_names_accordion',
  RELATED_QUERIES: 'related_queries',
  RELATED_TOPICS: 'related_topics',
  RELATED_TOPIC_POPOVER: 'related_topic_popover',
  RESULT_SNIPPET: 'result_snippet',
  SEARCH_BAR: 'search_bar',
  SEARCH_FILTER: 'search_filter',
  SEARCH_IN_OTHER_SECTION: 'search_in_other_section',
  SERP: 'serp',
}

export type COMPONENT_CLASS = (typeof COMPONENT_CLASS)[keyof typeof COMPONENT_CLASS]

export const enum SECTION {
  ARTICLE = 'article',
  ARTICLE_NEWS = 'article-news',
  DOCTRINE = 'doctrine',
  GENERAL = 'general',
  JOURNAL = 'journal',
  JURISPRUDENCE = 'jurisprudence',
  LAW = 'law',
  LAWSUIT = 'lawsuit',
  MODEL = 'model',
  MOTION = 'motion',
  NEWS = 'news',
}

export const EVENT = {
  CHECKOUT_COMPLETE: 'checkout_complete',
  CHECKOUT_START: 'checkout_start',
  CLICK: 'click',
  CONTENT_GENERATE: 'content_generate',
  CUSTOM_COMPONENT_RENDERED: 'custom_component_rendered',
  FEATURE_USE: 'feature_use',
  FEEDBACK_GIVEN: 'feedback_given',
  INPUT_START: 'input_start',
  INPUT_SUBMIT: 'input_submit',
  SEARCH: 'search',
  SERP_RESULT_SELECTED: 'serp_result_selected',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
}

export const PAGE_POSITION = {
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  MIDDLE_CENTER: 'middle-center',
  MIDDLE_LEFT: 'middle-left',
  MIDDLE_RIGHT: 'middle-right',
  TOP_CENTER: 'top-center',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
}

export const SELECTED_OPTION = {
  ABNT_QUOTE_BUTTON: 'abnt_quote_button',
  COLLAPSE: 'collapse',
  COMMENT_DOCUMENT: 'comment_document',
  COPY_BUTTON: 'copy_button',
  COPY_COMMAND: 'copy_command',
  DOCUMENT_INDEX: 'document_index',
  DOCUMENT_INDEX_ITEM: 'document_index_item',
  DOWNLOAD_BUTTON: 'download_button',
  ENTITY_SEARCH_BUTTON: 'entity_search_button',
  EXPAND: 'expand',
  FULL_DOCUMENT_ACCESS: 'full_document_access',
  FULL_LAWSUIT_ACCESS: 'full_lawsuit_access',
  LEGAL_QUOTE_BUTTON: 'legal_quote_button',
  LEGAL_QUOTE: 'legal_quote',
  LIKE_DOCUMENT: 'like_document',
  NAME_LINK: 'name_link',
  NAME_UNVERIFIED_LAWSUIT: 'name_unverified_lawsuit',
  NEXT_CHAPTER: 'next_chapter',
  PAGINATION: 'pagination',
  PREVIOUS_CHAPTER: 'previous_chapter',
  RELATED_LEGIS: 'related_legis',
  RELATED_TOPIC: 'related_topic',
  RELATED_TOPIC_SEARCH: 'related_topic_search',
  REPLY_COMMENT: 'reply_comment',
  RESULT_DOCTRINE_SECTION_TITLE: 'result_doctrine_section_title',
  RESULT_SHOW_MORE: 'result_show_more',
  RESULT_TITLE: 'result_title',
  SAVE_DOCUMENT: 'save_document',
  SCROLL_TO_TOP: 'scroll_to_top',
  SEARCH_IN_DOCUMENT_BUTTON: 'search_in_document_button',
  SHOW_LAWSUIT: 'show_lawsuit',
  SHOW_LESS: 'show_less',
  SHOW_MORE: 'show_more',
  VERIFY_LAWSUITS: 'verify_lawsuits',
  VIEW_MORE: 'view_more',
  SHOPPING_BAG: 'shopping_bag',
}

export const SEARCH_PATH_TO_SECTION: Record<string, SECTION> = {
  '/busca': SECTION.GENERAL,
  '/artigos-noticias/busca': SECTION.ARTICLE_NEWS,
  '/jurisprudencia/busca': SECTION.JURISPRUDENCE,
  '/diarios/busca': SECTION.JOURNAL,
  '/pecas/busca': SECTION.MOTION,
  '/doutrina/busca': SECTION.DOCTRINE,
  '/legislacao/busca': SECTION.LAW,
  '/modelos-pecas/busca': SECTION.MODEL,
  '/consulta-processual/busca': SECTION.LAWSUIT,
}

export const FEATURE_CLASS = {
  COPY_CONTENT: 'copy_content',
  DOCUMENT_DOWNLOAD: 'document_download',
  DOCUMENT_SAVE: 'document_save',
  DOCUMENT_SEARCH: 'document_search',
  FORMATTING_TOOL: 'formatting_tool',
  NAME_MONITORING: 'name_monitoring',
  UNLOCK_DOCUMENT_FILES: 'unlock_document_files',
  UNLOCK_DOCUMENT_INFORMATION: 'unlock_document_information',
  VERIFY_DOCUMENTS: 'verify_documents',
} as const

export type FEATURE_CLASS = (typeof FEATURE_CLASS)[keyof typeof FEATURE_CLASS]

export const FEATURE_NAME = {
  ABNT_QUOTE: 'abnt_quote',
  ARTICLE_DOCUMENT_SAVE: 'article_document_save',
  ARTICLE_DOCUMENT_SEARH: 'article_document_search',
  COPY_ARTICLE_CONTENT: 'copy_article_content',
  COPY_DOCTRINE_CONTENT: 'copy_doctrine_content',
  COPY_JOURNAL_CONTENT: 'copy_journal_content',
  COPY_JURISPRUDENCE_CONTENT: 'copy_jurisprudence_content',
  COPY_LAW_CONTENT: 'copy_law_content',
  COPY_LAWSUIT_CONTENT: 'copy_lawsuit_content',
  COPY_MODEL_CONTENT: 'copy_model_content',
  COPY_MOTION_CONTENT: 'copy_motion_content',
  COPY_NEWS_CONTENT: 'copy_news_content',
  DISABLE_NAME_MONITORING: 'disable_name_monitoring',
  DOCTRINE_CITATION: 'doctrine_citation',
  DOCTRINE_DOCUMENT_SAVE: 'doctrine_document_save',
  DOCTRINE_DOCUMENT_SEARCH: 'doctrine_document_search',
  DOWNLOAD_JURIS_CONTENT: 'download_juris_content',
  DOWNLOAD_MODEL_CONTENT: 'download_model_content',
  DOWNLOAD_MOTION_CONTENT: 'download_motion_content',
  ENABLE_NAME_MONITORING: 'enable_name_monitoring',
  JURISPRUDENCE_CITATION: 'jurisprudence_citation',
  JURISPRUDENCE_DOCUMENT_SAVE: 'jurisprudence_document_save',
  JURISPRUDENCE_DOCUMENT_SEARCH: 'jurisprudence_document_search',
  LAWSUIT_DOCS_LINK: 'link_docs_processo',
  LEGAL_QUOTE: 'legal_quote',
  MESSAGE_SUBMIT: 'message_submit',
  MODEL_DOCUMENT_SAVE: 'model_document_save',
  MODEL_DOCUMENT_SEARCH: 'model_document_search',
  MOTION_DOCUMENT_SAVE: 'motion_document_save',
  MOTION_DOCUMENT_SEARCH: 'motion_document_search',
  NEWS_DOCUMENT_SAVE: 'news_document_save',
  NEWS_DOCUMENT_SEARCH: 'news_document_search',
  START_TYPING: 'start_typing',
  UNLOCK_CASE_LAW_INFORMATION: 'unlock_case_law_information',
  VERIFY_LAWSUIT_UPDATE: 'verify_lawsuit_update',
} as const

export type FEATURE_NAME = (typeof FEATURE_NAME)[keyof typeof FEATURE_NAME]

export const REQUIRED_USER_STATUS = {
  AUTHENTICATED_USER: 'authenticated_user',
  SUBSCRIBER_USER: 'subscriber_user',
  UNAUTHENTICATED_USER: 'unauthenticated_user',
}

export const FEEDBACK_CLASS = {
  COMMENT_FEEDBACK: 'comment_feedback',
  CONTENT_FEEDBACK: 'content_feedback',
} as const

export const FEEDBACK_NAME = {
  ARTICLE_COMMENT_FEEDBACK: 'article_comment_feedback',
  ARTICLE_CONTENT_FEEDBACK: 'article_content_feedback',
  MODEL_COMMENT_FEEDBACK: 'model_comment_feedback',
  NEWS_COMMENT_FEEDBACK: 'news_comment_feedback',
  NEWS_CONTENT_FEEDBACK: 'news_content_feedback',
} as const

export const FEEDBACK_METHOD = {
  LIKE: 'like',
} as const

export const INPUT_METHOD = {
  FREE_TEXT: 'free_text',
} as const

export type INPUT_METHOD = (typeof INPUT_METHOD)[keyof typeof INPUT_METHOD]

export const ITEM_LIST_OPTION = {
  SHOW_LAWSUIT: 'show_lawsuit',
}

export const ITEM_LIST_COMPONENT_RENDERED_CLASS = {
  LAWSUIT_CARD: 'lawsuit_card',
  RELATED_NAMES_ACCORDION: 'related_names_accordion',
}
export const ITEM_LIST_COMPONENT_RENDERED_NAME = {
  UNRESOLVED_ENTITY_RELATED_LAWSUIT: 'unresolved_entity_related_lawsuit',
  PERSON_RELATED_LAWSUIT: 'person_related_lawsuit',
  RELATED_NAMES_LIST: 'related_names_list',
}
